.background-transition {
  transition: background-color 0.5s ease-in; /* Apply a 1-second fade */
}

/* slide in  */

@keyframes slideIn {
  0% {
    transform: translateX(-20%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

h1.slide-in,
h2.slide-in,
p.slide-in,
.booking-item.slide-in {
  animation: slideIn 0.5s ease-out forwards;
}

/* Staggerd trigger li */

.hiw-list-animate li {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.hiw-list-animate li:nth-child(1) {
  transition-delay: 0.1s;
}

.hiw-list-animate li:nth-child(2) {
  transition-delay: 0.2s;
}

.hiw-list-animate li:nth-child(3) {
  transition-delay: 0.3s;
}

.hiw-list-animate li:nth-child(4) {
  transition-delay: 0.3s;
}

.hiw-list-animate li:nth-child(5) {
  transition-delay: 0.3s;
}

.hiw-list-animate li:nth-child(6) {
  transition-delay: 0.3s;
}

.hiw-list-animate li.animate-in {
  opacity: 1;
  transform: translateY(0);
}

/* Stagger trigger inputs */

.title-section {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.animate-in4.title-section {
  opacity: 1;
  transform: translateY(0);
}

.animate-item {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.animate-in4 .animate-item {
  opacity: 1;
  transform: translateY(0);
}

.director {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.animate-in4 .director {
  opacity: 1;
  transform: translateY(0);
}



.animate-in4 .animate-item:nth-child(1) {
  transition-delay: 0.1s;
}
.animate-in4 .animate-item:nth-child(2) {
  transition-delay: 0.2s;
}
.animate-in4 .animate-item:nth-child(3) {
  transition-delay: 0.3s;
}
.animate-in4 .animate-item:nth-child(4) {
  transition-delay: 0.4s;
}
.animate-in4 .animate-item:nth-child(5) {
  transition-delay: 0.5s;
}
.animate-in4 .animate-item:nth-child(6) {
  transition-delay: 0.6s;
}
.animate-in4 .animate-item:nth-child(7) {
  transition-delay: 0.7s;
}
.animate-in4 .animate-item:nth-child(8) {
  transition-delay: 0.8s;
}
.animate-in4 .animate-item:nth-child(9) {
  transition-delay: 0.9s;
}
.animate-in4 .animate-item:nth-child(10) {
  transition-delay: 0.9s
}
.animate-in4 .animate-item:nth-child(11) {
  transition-delay: 1s;
}
.animate-in4 .animate-item:nth-child(12) {
  transition-delay: 1.1s;
}
.animate-in4 .animate-item:nth-child(13) {
  transition-delay: 1.2s;
}
.animate-in4 .animate-item:nth-child(14) {
  transition-delay: 1.2s;
}
.animate-in4 .animate-item:nth-child(15) {
  transition-delay: 1.2s;
}
.animate-in4 .animate-item:nth-child(16) {
  transition-delay: 1.2s;
}
.animate-in4 .animate-item:nth-child(17) {
  transition-delay: 1.2s;
}
.animate-in4 .animate-item:nth-child(18) {
  transition-delay: 1.2s;
}
.animate-in4 .animate-item:nth-child(19) {
  transition-delay: 1.2s;
}

.animate-in4 input,
.animate-in4 textarea {
  transition: border-color 0.3s ease-out, box-shadow 0.3s ease-out;
}

/* Animations Fade up */

.fade-in {
  opacity: 0;
  animation: fadeIn 0.8s ease-out forwards;
}

.animate-in.visible {
  opacity: 1;
  transform: translateY(0);
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.head-home .block,
.sign-up .block {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.8s ease-out, transform 0.8s ease-out;
}

.head-home .block.animate-in,
.sign-up .block.animate-in  {
  opacity: 1;
  transform: translateY(0);
}

/* Animation fade in-up */

h1.fade-in-up,
h2.fade-in-up,
p.fade-in-up {
  animation: fadeInUp 0.5s ease-out forwards;
}

.bg-head img {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.8s ease-out, transform 0.8s ease-out;
}

.bg-head img.fade-in-up {
  opacity: 1;
  transform: translateY(0);
}

.content-wrapper {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.8s ease-out, transform 0.8s ease-out;
}

.animate-inup .content-wrapper {
  opacity: 1;
  transform: translateY(0);
}

.svg-path {
  stroke-dasharray: 500;
  stroke-dashoffset: 500;
  transition: stroke-dashoffset 1.5s ease-out;
}

.animate-line {
  stroke-dashoffset: 0;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* ABOUT */

.content-animation {
  opacity: 0;
  transform: translateX(-30px);
  transition: opacity 0.8s ease-out, transform 0.8s ease-out;
}

.content-animation-reverse {
  opacity: 0;
  transform: translateX(30px);
  transition: opacity 0.8s ease-out, transform 0.8s ease-out;
}

.content-animation.animate-in3,
.content-animation-reverse.animate-in3 {
  opacity: 1;
  transform: translateX(0);
}

.content-animation.animate-in3 {
  opacity: 1;
  transform: translateX(0);
}

.mascotte-img {
  opacity: 0;
  transform: translateX(30px);
  transition: opacity 0.8s ease-out, transform 0.8s ease-out;
}

.mascotte-img.animate-in3 {
  opacity: 1;
  transform: translateX(0);
}

/* Staggered paragraph animations */
.content-animation.animate-in .subtitle {
  animation: fadeInUp 0.8s ease-out forwards;
}

.content-animation.animate-in .paragraph-1 {
  animation: fadeInUp 0.8s ease-out 0.2s forwards;
}

.content-animation.animate-in .paragraph-2 {
  animation: fadeInUp 0.8s ease-out 0.4s forwards;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Optional: Add a subtle scale effect to the mascot */
.mascotte-img img {
  transform: scale(0.95);
  transition: transform 0.8s ease-out;
}

.mascotte-img.animate-in img {
  transform: scale(1);
}

.mascotte-img2 {
  opacity: 0;
  transform: translateY(30px);
  transition: opacity 0.8s ease-out, transform 0.8s ease-out;
}

.mascotte-img2.fade-in-up {
  opacity: 1;
  transform: translateY(0);
}

.copied-message{
    position: fixed;
    top: 1rem;
    left: 1rem;
    width: 100%;
    max-width: 200px;
    padding: 1rem;
    color: var(--secondary-color);
    border-radius: 20px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

@media screen and (max-width: 1024px) {
    .copied-message{
        display:none;
    }
}



.verification-form{
    width: 100%;
    max-width: 500px;
  }
  
  .popup{
      position: fixed;
      display: flex;
      flex-direction: column;
      top: 0;
      left: 0;
      justify-content: center;
      align-items: center;
      width: 100vw;
      height: 100vh;
      z-index: 100;
      background-color: var(--secondary-color);
  }
  
  .popup-box{
    width: 50%;
  }
  
  .overlay-container-2 {
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 1000;
    backdrop-filter: blur(5px);
  }
  
  .popup-small {
    position: relative;
    width: 90%;
    height: auto;
    max-width: 500px;
  }
  
  .popup-elements {
    width: 100%;
    height: auto;
    padding: 2rem ;
    background-color: var(--primary-color);
    border-radius: 20px;
  }
  
  .popup form{
    height: 100%;
  }
  
    

  


.box{
    padding: 1.5rem;
    border-radius: 20px;
    background-color: rgb(255, 255, 255);
    color: var(--secondary-color);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

}

.box-prim{
    padding: 1.5rem;
    border-radius: 20px;
    background-color: rgb(255, 255, 255);
    color: var(--primary-color);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

}

.box-transparent{
    color: white;
    background-color: transparent;
    border: none;
}

.box-shadow{
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.box-top{
    display: flex;
    justify-content: space-between;
}


.box-top button{
    color: var(--primary-color);
}


.box-1 {
    grid-column: span 4;
}
.box-2 {
    grid-column: span 4;
}
.box-3 {
    grid-column: span 8;
}
.box-4 {
    grid-column: span 12;
}



@media screen and (max-width: 1080px) {
    .box-1, .box-2{
        grid-column: span 6;
    }
    .box-3{
        grid-column: span 12;
    }
}


@media screen and (max-width: 767px) {


    .box-1, .box-2{
        grid-column: span 12;
    }

}


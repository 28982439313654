.navbar {
    position: relative;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    padding: .5rem 0;
}

.navbar-mobile {
    display: none;
    position: fixed;
    bottom: -8.25rem;
    left: 0;
    width: auto;
    height: auto;
    padding: .5rem 0;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    background-color: var(--secondary-color);
    transform: translateY(-200%);
    transition: all .25s ease;
    z-index: 10;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    z-index: 998;
}

.mobile-nav-container {
    max-width: 1440px;
    padding: 0;
    width: 100%;
  }

  .navbar-bottom ul  {
    width: 100vw;
  }

  .bg-primary-color{
    background-color: var(--secondary-color) !important;
  }

.navbar-links li.active {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    animation: bounceIn 0.3s ease-in-out;
    transition: all 1s ease-in;
  }

  .navbar-links li.active-default {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    animation: bounceIn 0.3s ease-in-out;
    transition: all 1s ease-in;
  }


  .navbar-mobile-button{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3.5rem;
    height: 3.5rem;
    border: none;
    border-radius: 50%;
    background-color: var(--secondary-color);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    animation: bounceIn 0.3s ease-in-out;
    transition: all 1s ease-in;
  }


  .navbar-mobile-button.active {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
    background-color: white !important;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    animation: bounceIn 0.3s ease-in-out;
    transition: all 1s ease-in;
  }


  @keyframes bounceIn {
    0% { transform: scale(1); }
    50% { transform: scale(1.2); }
    100% { transform: scale(1.15); }
}

.navbar-links li.active {
    animation: bounceIn 0.3s ease-in-out;
}

  
.navbar-visible{
    display: flex;
    background-color: rgb(255, 255, 255, .9);

}

.navbar-flex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.navbar-logo {
    width: 4rem;
    height: 100%;
}

.navbar-logo img {
    margin-top: .25rem;
    width: 100%;
    height: 100%;
}


.navbar-links {
    width: 100%;
}




.navbar-menu ul {
    list-style: none;
    display: flex;
    padding-left: 3rem;
}

.navbar-menu ul li {
    margin-right: 50px;
}

.navbar-menu ul li a {
    color: white;
    text-decoration: none;
    font-size: 16px;
    font-weight: 600;
}

.navbar-signup{
    display: flex;
}

.navbar-signup-mobile{
    display: none;
}



@media screen and (max-width: 1024px) {
    .navbar-desktop {
        display: none !important;
    }

    .navbar-mobile {
        display: flex;
    }

    .navbar-signup{
        display: none;
    }

    .navbar-signup-mobile{
        display: block;
    }
    

}

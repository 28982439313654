.custom-button {
    font-family: "Fredoka", sans-serif;
    font-size: 1rem;
    font-weight: 300;
    white-space: nowrap;
    /* width: 100%; */
    height: auto;
    padding: .75rem 2rem;
    color: white;
    background-color: transparent;
    border: 3.5px solid white;
    border-radius: 7.5px;
    cursor: pointer;
}

.button {
    font-family: "Fredoka", sans-serif;
    font-size: 1rem;
    color: white;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.button-round{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
}

.disabled-button {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
}

.button-prim-color {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-color: var(--primary-color);
    background-color: var(--primary-color);
}

.button-choiceSec {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-color: var(--secondary-color);
    background-color: var(--secondary-color);
}

.button-border-sec-color {
    color: var(--secondary-color);
    border: 2px solid var(--secondary-color);
}

.icon {
    width: 2rem;
    margin-right: .5rem;
    height: auto;
}

.button-edit {
    width: auto;
    padding: 0.6rem;
    border: none;
}

.upload-button label{
    display: flex;
    justify-content: center;
    font-size: 1rem;
    margin: .5rem 0;
    padding: 1rem;
    border: 4px solid var(--secondary-color);
    border-radius: 10px;
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
}

.button-search {
    display: flex;
    padding: .6rem;
    width: auto !important;
    height: 100%;
}

.button-decline {
    padding: 0;
    border: none;
    background-color: transparent;
    /* color: var(--secondary-color); */
}

.button-delete {
    border: 10px;
    cursor: pointer;
    background-color: darkred;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

}

.button-cancel {
    width: 0;
    padding: 0 0.5rem;
    color: var(--secondary-color);
    border: none;
}

.button-slide {
    margin: 1rem;
    transition: opacity .5s ease;
    opacity: 1;
}

.button-slide:disabled {
    transition: opacity .5s ease;
    opacity: .5;
}



.button-mobile-save{
    position: fixed;
    bottom: 9.5rem;
    right: 1rem;
    z-index: 1000;
  }

.mobile-button{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    background-color: var(--primary-color);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    padding: 0;
    border: none;
}

.mobile-button-cancel{
    background-color: darkred;
}

@media screen and (max-width: 1300px) {
    .button-login,
    .button-registration {
        width: auto;
        padding: 0.7rem 0.9rem;
        margin: .5rem;
    }

    .button-login .button-text,
    .button-registration .button-text {
        display: none;
    }

    .icon {
        margin-right: 0;
    }
}


.hidden-input {
    display: none; /* Hides the default file input */
  }
  
  .upload-file{
    display: flex;
    justify-content: center;
    font-size: 1rem;
    margin: .5rem 0;
    padding: 10rem;
    border: 3px solid white;
    border-radius: 7.5px;
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
  

.file-name{
    max-width: 100vw;
    overflow: hidden; 
    white-space: nowrap; 
    text-overflow: ellipsis; 
    display: inline-block; 
}


.upload{
    display: flex;
}

.upload-item{
    display: flex;
    width: 100%;

}

.upload-item label{
    display: flex;
    font-size: 1rem;
    margin: .5rem 0;
    padding: 1rem;
    border: 4px solid white;
    border-radius: 10px;
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
}

.upload-file{
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: .5rem 0;
}  


.shopping-cart{
  position: relative;
}

.cart-count{
  position: absolute;
  top: -1.25rem;
  right: -.75rem;
  padding: .375rem;
  width: 1.25rem;
  height: 1.25rem;
  font-weight: 800;
}

@media screen and (max-width: 1024px) {
    .shopping-cart{
      position: fixed;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 3.5rem;
      height: 3.5rem;
      border-radius: 50%;
      background-color: var(--primary-color);
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
      bottom: 5rem;
      right: 1rem;
      z-index: 1000;
    }


    .shopping-cart a{
      position: static;
      top: 2.25rem;
      right: 7rem;
    }

    .cart-count{
      position: absolute;
      top: 0rem;
      right: 0rem;
      padding: .375rem;
      width: 1.25rem;
      height: 1.25rem;
      border-radius: 50%;
      font-weight: 800;
      background-color: var(--secondary-color);
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    }
    
}
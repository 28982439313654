@import url("https://fonts.googleapis.com/css2?family=Fredoka:wdth,wght@75..125,300..700&display=swap");
@import "./utilities.css";
@import "./typography.css";
@import "./checkbox.css";
@import "./error-message.css";
@import "./scrollbar.css";
@import "./animations.css";
@import "./calender.css";
@import "./lesson-card.css";
@import "./box.css";
@import "./clickable-item.css";
@import "./side-bar.css";
@import "./rating.css";
@import "./blur-container.css";
@import "./scrollable-list.css";
@import "./custom-dropdown.css";
@import "./sticky-component.css";
@import "./pop-ups.css";
@import "./review-component.css";
@import "./messages.css";
@import "./file-upload.css";
@import "./footer.css";





:root {
  --primary-color: #468275;
  --secondary-color: #59b2a5;
  --secondary-color2-opacity: rgb(89, 178, 165, 0.9);
  --secondary-color-opacity-5: rgb(89, 178, 165, 0.5);

  --font-size: 16px;
  --padding: 10px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Fredoka", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  color: rgb(255, 255, 255);
  background-color: var(--primary-color);
}


.dashboard-background {
  background-color: var(--secondary-color) !important; 
}

.cstm-container {
  max-width: 1780px;
  margin: 0 auto;
  padding: 0 15px ;
  width: 100%;
}


.bg-secondairy{
background-color: var(--secondary-color);
}

.white-text{
  color: white !important;
}

.primairy-color{
  color: var(--primary-color);
}

.secondairy-color{
  color: var(--secondary-color);
}

/* Tooltip container styles */
.status-tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

/* Tooltip text styles */
.status-tooltip::after {
  content: attr(data-tooltip); 
  position: absolute;
  bottom: 125%; /* Position it above the element */
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;  color: var(--primary-color);
  text-align: center;
  padding: 5px;
  border-radius: 5px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0s 0.3s;
  white-space: nowrap;
  z-index: 10;
}

/* Show the tooltip on hover */
.status-tooltip:hover::after {
  color: var(--primary-color);
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s ease;
}

/* Pending status specific styles */
.pending {
  position: absolute;
  top: 1.5rem;
  right: 1rem;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background-color: orange;
}


/* Confirmed status specific styles */
.confirmed {
  position: absolute;
  top: 1.5rem;
  right: 1rem;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background-color: #00DD1A;
}


.confirmed-payment{
  display: flex;
}


.pending-payment .status {
  width: auto;
  height: 100%;
  color: white;
  background-color: orange;
  border-radius: 20px ;
  font-size: 1rem; /* Default font size */
  padding: 1rem 2rem;
}

.status{
  max-width: 300px;
  overflow: hidden; /* Ensure content beyond width is hidden */
  white-space: nowrap; /* Prevent wrapping */
  text-overflow: ellipsis; /* Add ellipsis for overflowed text */
  display: inline-block;
}


@media (max-width: 768px) {
  .confirmed-payment {
    font-size: 1.5rem; /* Adjust font size for smaller screens */
  }
}






.my-calender-item ul::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Edge */
}



  .mascotte-img img{
    width: auto;
    height: 30rem;
  }

  .mascotte-img2 img{
    width: auto;
    height: 30rem;
  }
  
  @media screen  and (max-width: 576px){
    .mascotte-img img{
      width: 19rem;
      height: 25rem;
      overflow: hidden;
    }
    .mascotte-img2 img{
      width: 19rem;
    height: 25rem;
      overflow: hidden;
    }
  
  }


  .rdp-chevron{
    fill: white !important;
  }
  
  .rdp-range_start,.rdp-selected .rdp-today {
    background: linear-gradient(to right, var(--secondary-color), transparent 50%, var(--primary-color) 50%) !important;
  }
 .rdp-range_end,.rdp-selected .rdp-today {
    background: linear-gradient(to left, var(--secondary-color), transparent 50%, var(--primary-color) 50%) !important;
  }


  .rdp-range_start .rdp-day_button {
    background-color: var(--primary-color) !important;
  }
  
  .rdp-range_end .rdp-day_button {
    background-color: var(--primary-color) !important;
  }
  
  .rdp-range_middle {
    background-color: var(--primary-color) !important;
  }
  
  
  .rdp-selected .rdp-day_button{
    border-width: 5px !important;
    border-color:white !important;
  }
  
  .rdp-today:not(.rdp-outside) {
    color: white !important;
    font-weight: 800 ;
  }
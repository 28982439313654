.flex {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-colomn {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.flex-reverse {
  flex-direction: row-reverse;
}

.gap-05 {
  gap: 0.5rem;
}

.gap-2 {
  gap: 2rem;
}

.grid-overview {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-auto-flow: dense;
  gap: 2rem;
  padding: 2rem 0;
}

.grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-auto-flow: dense;
  gap: 2rem;
  padding: 2rem 0;
  overflow: hidden;
}

@media (max-width: 1024px) {
  .grid-reverse {
    display: flex;
    flex-direction: column-reverse;
  }
}

@media (max-width: 576px) {
  .grid-overview {
    gap: 0;
  }
}

.grid-item {
  background-color: white;
  color: var(--secondary-color);
  padding: 1.5rem;
  border-radius: 20px;
}

.colom-1 {
  grid-column: span 1;
}
.colom-2 {
  grid-column: span 2;
}
.colom-3 {
  grid-column: span 3;
}
.colom-4 {
  grid-column: span 4;
}
.colom-5 {
  grid-column: span 5;
}
.colom-6 {
  grid-column: span 6;
}
.colom-7 {
  grid-column: span 7;
}
.colom-8 {
  grid-column: span 8;
}
.colom-9 {
  grid-column: span 9;
}
.colom-10 {
  grid-column: span 10;
}
.colom-11 {
  grid-column: span 11;
}
.colom-12 {
  grid-column: span 12;
}

@media screen and (max-width: 1024px) {
  .colom-medium-2 {
    grid-column: span 2;
  }
  .colom-medium-6 {
    grid-column: span 6;
  }
  .colom-medium-10 {
    grid-column: span 10;
  }
  .colom-medium-12 {
    grid-column: span 12;
  }

  .mobile-direction-column {
    flex-direction: column;
  }
}

@media screen and (max-width: 776px) {
  .colom-small-12 {
    grid-column: span 12;
  }
}

.text-align-center {
  text-align: center;
}

.justify-content-center {
  justify-content: center;
}
.align-items-center {
  align-items: center;
}

.justify-content-end {
  justify-content: end !important;
}
.justify-space-between {
  justify-content: space-between;
}
.align-items-start {
  align-items: start;
}
.align-items-center {
  align-items: center;
}
.align-items-end {
  align-items: end;
}

.space-around {
  justify-content: space-around;
}

.space-between {
  justify-content: space-between;
}

.sticky-element {
  position: sticky;
  top: 0;
}
.w-100 {
  width: 100%;
}

.m-05 {
  margin: 0.5rem;
}

.mt-05 {
  margin-top: 0.5rem;
}

.mt-1 {
  margin-top: 1rem;
}

.mt-2 {
  margin-top: 2rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

.mtb-1 {
  margin: 1rem 0;
}

.mtb-2 {
  margin: 2rem 0;
}

.mr-1 {
  margin-right: 1rem;
}

.mr-2 {
  margin-right: 2rem;
}

.p-1 {
  padding: 1rem !important;
}

.p-2 {
  padding: 2rem;
}

.p-3 {
  padding: 3rem;
}

.pt-1 {
  padding-top: 1rem;
}

.pb-1 {
  padding-bottom: 1rem;
}
.pb-2 {
  padding-bottom: 2rem;
}

.ptb-05 {
  padding: 0.5rem 0;
}

.ptb-1 {
  padding: 1rem 0;
}
.ptb-1-05 {
  padding: 1.5rem 0;
}

.input-50 {
  width: 50% !important;
}

.input-75 {
  width: 75% !important;
}

.input-100 {
  width: 100% !important;
}

.input-150 {
  width: 150% !important;
}

.input-200 {
  width: 200% !important;
}

@media screen and (max-width: 992px) {
  .input-200,
  .input-150,
  .input-100,
  .input-75,
  .input-50 {
    width: 100% !important;
  }
}

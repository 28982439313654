.user-table-relative{
  position: relative;
}

.user-table-container {
  max-width: 100%;
  max-height: 100vh;
  overflow-x: hidden;
  border: none;
  border-radius: 8px;
  overflow-x: scroll;
  scrollbar-width: none; /* Hides scrollbar in Firefox */
  -ms-overflow-style: none; /* Hides scrollbar in IE and Edge */
}
.relative{
  position: relative;
}
.user-table-container--400 {
  max-height: 400px;
}
.user-table-container--300 {
  max-height: 350px;
}

.scroll-arrow {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  border-radius: 50%;
  animation: bounce 1s infinite alternate;
}

/* Arrow bounce animation */
@keyframes bounce {
  from {
    transform: translateX(-50%) translateY(0);
  }
  to {
    transform: translateX(-50%) translateY(5px);
  }
}

.user-table {
  width: 100%;
  border-collapse: collapse;
}

.user-table th {
  position: sticky;
  top: 0;
  backdrop-filter: blur(5px); 

}

.user-table th,
.user-table td {
  width: auto;
  max-width: 200px;
  padding: 1.5rem;
  text-align: left;
  border: none;
  border-bottom: 1px solid white;
  overflow: hidden;
  text-overflow: ellipsis;
}

.user-table td.email-column {
  max-width: 300px; /* Email column gets more space */
}

.user-table-row.clickable-row {
  cursor: pointer;
  transition: all 0.1s ease-in;
}

.user-table-row.clickable-row:hover {
  opacity: 0.5;
  transition: all 0.1s ease-in;
}

.head-home{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 45rem;
    margin: 5rem 0;
    overflow: hidden;
    z-index: 0;
}

.bg-head {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; 
    z-index: -1;
    overflow: hidden; 
}

.head-content{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    height: 100%;
}

.bg-head img {
    width: 100%;
    height: 100%;
    object-fit: contain; 
    object-position: center; 
}


.block{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: auto;
    height: auto;
    padding: 0 5rem;
}

.block-description{
    text-align: end;
    padding: 1rem 0;
}



@media screen and (max-width:1024px) {
    .head-home{
        margin: 2rem 0;
    }
    .head-content{
        margin-top: 2.5rem;
    }
    .block{
        width: auto;
        padding: 2.5rem 0;
    }

    .block-description{
        text-align: center;
    }
}

@media screen and (max-width:576px) {
    .head-home{
        height: 35rem;
    }

    .block{
        width: auto;
        padding: 2.5rem 0;
    }

    .block-description{
        text-align: center;
    }
}
.side-bar {
    display: flex;
    flex-direction: column;
    position: fixed;
    overflow: hidden;
    overflow-y: scroll;
    top: 0;
    right: 0;
    width: 100%; 
    max-width: 50%;
    min-height: 100vh;
    height: 100%;
    padding: 1rem;
    background-color: var(--secondary-color);
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
    transform: translateX(100%);
    transition: all 0.3s ease-in-out;
    z-index: 999;
  }
  
  .side-bar.show {
    transform: translateX(0); /* Show the element by moving it to the left */
  }
  
  .side-bar.hide {
    transform: translateX(100%); /* Hide the element by moving it out of view */
  }


  /* Side bar navigation lesson */

  .side-nav{
    transition: all .3s ease-out;
  }

  .side-nav.active{
    padding: 1rem;
    color: var(--secondary-color);
    border-radius: 5px;
    background-color: white;
  }

  .side-bar-label{
    font-size: 1.5rem;
    font-weight: bold;
  }
  .side-bar-label p{
    font-size: .75rem;
  }

  @media screen and (max-width: 1024px) {
    .side-nav.active{
      padding: 1rem 0;
      color: var(--secondary-color);
      border-radius: 5px;
      background-color:transparent;
      transform: translate(-.5rem);
      transition: all .1s ease-in;
    }
  }

  @media screen and (max-width: 576px) {
    .side-bar{
      padding: 1rem;
      max-width: 100%;
    }

    .side-bar-label{
      font-size: 1rem;
    }
  }
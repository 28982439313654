.dropdown-content {
    overflow: hidden;
    max-height: 0; /* Initially hidden */
    transition: max-height 0.4s ease, opacity 0.4s ease;
    opacity: 0;
  }
  
  .dropdown-content.open {
    max-height: 600px; 
    opacity: 1;
    padding: 1rem 0;
  }
.time-slot{
    position: sticky;
    top: 2rem;
    left: -30rem;
    margin-top: 2rem;
    z-index: 997;
  }
  
  .my-calender-content{
    width: 100%;
    height: calc(100% - 4rem);
  }
  
  .my-calender-content h2{
    padding: 2rem 0;
  }

.my-calender-counts{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: .8rem;
  width: 1.5rem;
  height: 1.5rem;
  background-color: var(--primary-color);
  border-radius: 50%;
}

.my-calender-counts--disabled{
  background-color: darkred;
}
  

  .my-calender-item{
    width: 100%;
    margin: 1rem;
    overflow: hidden;
    max-width: 250px;
  }



  .my-calender-card{
    position: relative;
    width: 250px;
    color: var(--primary-color);
    padding: 0.5rem;
    margin-top: 1rem;
    color: var(--secondary-color);
    background-color: white;
    border-radius: 10px;
    font-size: .9rem;
    overflow: hidden;
  }



  .my-calender-list {
    display: flex; 
    overflow-x: auto; 
    overflow-y: scroll; 
    scroll-behavior: smooth; 
    white-space: nowrap; 
    padding: 0;
    margin: 0;
    cursor: grab;
  }

  .my-calender-list {
    display: flex; 
    overflow-x: auto; 
    overflow-y: hidden; 
    scroll-behavior: smooth; 
    white-space: nowrap; 
    padding: 0;
    margin: 0;
    cursor: grab;
  }
  
  .my-calender-list:active {
    cursor: grabbing;
  }
  
  .my-calender-list__item {
    flex: 0 0 auto; /* Prevent shrinking/growing */
    margin-right: 1rem;
    width: calc(450px - 1rem); /* Adjust width */
  }
  
  
  .change-width{
    width: calc(100% / 2 - 2rem);
  
  }
  
  .change-width h3{
    font-size: 2rem;
    white-space: nowrap; 
    text-overflow: ellipsis;
    overflow: hidden;
  }
  

  
  .my-calender-default{
    width: 100%;
    height: 100vh;
    max-height: 33rem;
  
  }

  .calender-filter__options{
    display: flex;
    flex-direction: column;
    width: auto;
  }
  
  
  .admin-view{
    position: relative;
    background-color: white;
    color: var(--secondary-color);
    border-radius: 20px;
    min-width: 300px;

  }
  
  .user{
    position: relative;
  }
  
  .user-popup{
    position: absolute;
    width: 100%;
    min-width: 350px;
    top: -9rem;
    left: 0;
    padding: 1rem;
    border-radius: 20px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    z-index: 999;
  
  }

  
  @media screen and (max-width: 1280px) {
  
    .change-width{
      width: calc(100% / 2 - 2rem);
    
    }
  }
  
  @media screen and (max-width: 1024.5px) {
    .calender {
      display: flex;
      flex-direction: column; 
    }
  
    .time-slot {
      display: flex;
      flex: 1;
      width: 100%;
      justify-content: space-evenly;
      padding: 0 1rem;
      position: static; 
      z-index: 997;
    }
  
    .my-calender-content {
      flex: 2;
      margin-top: 1rem; 
      padding: 0 1rem;
    }
  
  
    .my-calender-item {
      max-width: 100%;
      margin: 0.5rem;
    }
  
  }
  
  
  @media screen and (max-width: 767px) {

    .calender-filter__options{
        flex-direction: row;
        width: 100%;
        gap: 1rem;
        padding: 2rem 0;
      }
      
       .time-slot {
            flex-direction: column;
            align-items: center;
         }
  

    .rdp-months {
      display: flex;
      justify-content: center;
      max-width: 100%;
    }
  
    .rdp-nav{
      position: static;
    }
  

  
  }
  
  
  @media screen and (max-width: 576px) {
  
    .rdp-nav{
      position: absolute;
    }
  
    .my-calender-item {
      width: 100%; 
      margin: 0;
    }
    

  }
  
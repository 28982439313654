.review{
    padding: 1.5rem;
  }
  .review-tutor h3{
      margin-left: 1rem;
  }
  
  .score{
      width: 5rem !important;
  }
  
  .review-succes{
    width: 100%;
    height: 100%;
  }
  
  .new-badge{
    font-weight: bold;
    color: white;
    background-color: var(--primary-color);
    padding: .5rem 1rem;
    border-radius: 20px;
  }
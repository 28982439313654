.rating img {
    width: 3rem;
  }

  .rating-review img {
    width: 2rem;
  }
  
  .rating-score {
    font-size: 2rem;
  }

  @media screen and (max-width: 1200px) {
    .rating-score {
      font-size: 3rem;
    }
    .rating img {
      width: 4rem;
    }
  }
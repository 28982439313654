/* Checkbox */


input[type="checkbox"] {
    width: 1.5rem;
    height: 1.5rem;
    border: 2px solid white;
    transition: all 0.25s ease;
  }
  

.checkbox {
    display: flex;
    align-items: center;
    height: auto;
    margin: 1.25rem 0 2rem 0;
    border-radius: 7.5px;
  
  }
  
  .checkbox-text {
    font-size: 1rem;
    color: white;
    margin-left: 0.5rem;
  }
  
  /* END CHECK BOX */
.dropdown {
  position: relative;
  border: 3.5px solid white;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  z-index: 996;
}

.user {
  display: flex;
  align-items: center;
  gap: 10px;
}


.user-name {
  padding: 0 1.5rem 0 0.75rem;
}

.logout-btn {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.logout-btn:hover {
  opacity: 0.7;
}

@media screen and (max-width: 1024px) {
  .user-name {
    display: none;
  }


}

@media screen and (max-width: 776px) {

  .dropdown {
    position: absolute;
    top: 1rem;
    right: 0;
  }
}


.search-bar input{
    font-family: "Fredoka", sans-serif;
    font-size: 1rem;
    padding: 1rem 1rem;
    border: none;
    background-color: white;
    border-radius: 7.5px;
}

.search-bar input::placeholder{
    font-size: 1rem;
    font-weight: bold;
    color: var(--primary-color);
    text-transform: capitalize;
}


@media screen and  (max-width:776px) {
    .search-bar input{
        width: 100%;
    }
}
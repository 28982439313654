.lesson {
    position: relative;
    padding: 1.5rem;
    border-radius: 20px;
    background-color: rgb(255, 255, 255);
    color: var(--secondary-color);
    border: 4px solid white;
  }

  .user-img {
    width: 3rem;
    height: 3rem;
    object-fit: cover;
    border-radius: 50%;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }



  .user-img-big {
    width: 10rem;
    height: 10rem;
    object-fit: cover;
    border-radius: 50%;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }

  .user-name{
    max-width: 200px;
    overflow: hidden; /* Ensure content beyond width is hidden */
    white-space: nowrap; /* Prevent wrapping */
    text-overflow: ellipsis; /* Add ellipsis for overflowed text */
    display: inline-block; /* Ensure ellipsis works */
  }
  
  .users-name p {
    display: inline-block; 
    font-size: 1.25rem;
    font-weight: bold;
  }

  .lesson-teacher p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1.25rem;
    font-weight: bold;
    max-width: 200px;
    overflow: hidden; /* Ensure content beyond width is hidden */
    white-space: nowrap; /* Prevent wrapping */
    text-overflow: ellipsis; /* Add ellipsis for overflowed text */
    display: inline-block; /* Ensure ellipsis works */
  }
  
  .lesson-date-time {
    margin-top: 1rem;
    border-top: 2px solid var(--secondary-color);
    border-bottom: 2px solid var(--secondary-color);
  }
  
  .lesson-type {
    position: absolute;
    top: -2rem;
    right: -2rem;
    font-size: 1.1rem;
    font-weight: 500;
    text-transform: uppercase;
    color: white;
    padding: 1rem 2rem;
    border-radius: 5rem;
    background-color: var(--primary-color);
  }
  
  .lesson-delete {
    position: absolute;
    top: -1rem;
    right: -1rem;
    font-size: 1.1rem;
    font-weight: 500;
    text-transform: uppercase;
    color: white;
    padding: .5rem;
    border-radius: 50%;
    background-color: var(--secondary-color);
  }
  
  .lesson-appointment {
    padding-left: 1.2rem;
  }
  
  .lesson-info {
    padding: .5rem 1rem;
  }

  
  @media screen and (max-width: 576px) {
    .lesson-type {
        right: -1rem;
        padding: 1rem 1.5rem;
        font-size: 1.3rem;
    }
  
    .lesson-teacher p {
        font-size: 1.05rem;
    }
  
    .lesson-appointment {
        padding-left: 0;
    }

    .users-name p {
      white-space: nowrap;
      max-width: 50vw;
      overflow: hidden; /* Ensure content beyond width is hidden */
      white-space: nowrap; /* Prevent wrapping */
      text-overflow: ellipsis; /* Add ellipsis for overflowed text */
      display: inline-block; /* Ensure ellipsis works */
      font-size: 1.25rem;
      font-weight: bold;
    }


  }
  


  /* Reservation style */

  .reservation-item{
    position: relative;
  }

  .reservation-price{
    font-size: 2rem;
  }
  
  .reservation-price .amount{
    font-size: 3rem;
  }
  
  .reservation-price .btw-indicator{
    font-size: .9rem;
  }

  .reservation-info-course{
    font-size: 2rem;
    font-weight: bold;
  }



  
  .image-container {
    cursor: pointer;
    display: inline-block;
    margin: 10px;
  }

  .status-absolute{
    top: -1rem;
    right: -1rem;
    position: absolute;
  }

  .payment-status {
    display: flex;
    justify-content: center;
    width: 5rem;
    height: auto;
    color: white;
    background-color:var(--primary-color);
    border-radius: 5rem ;
    font-size: 1rem; /* Default font size */
    padding: 1rem 2rem;
  }
  .payment-status-pending {
    background-color:orange;

  }
  .payment-status-cancel {
    background-color:darkred;

  }
  

  .profile-image {
    width: 2rem;
    height: 2rem;
    object-fit: cover;
    border-radius: 50%;
    transition: transform 0.3s ease;
  }
  
  .image-container.active .profile-image {
    transform: scale(1.1);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  
  
  .calendar-container {
    width: 80px;
    height: auto;
    padding: .5rem 1rem .75rem 1rem;
    background: white;
    border-top: 10px solid var(--primary-color); 
    border-radius: 8px;
    box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.2);
    text-align: center;
    position: relative;
    box-sizing: border-box; /* Ensures padding and border don't change element size */
  }
  
  
  .calendar-rings {
    position: absolute;
    top: -15px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 15px;
  }
  
  .ring {
    width: 6px;
    height: 12px;
    background: var(--secondary-color);
    border-radius: 2px;
  }
  
  
  .calendar-month {
    font-size: 12px;
    font-weight: bold;
    color: var(--primary-color);
  }
  
  .calendar-day {
    font-size: 2rem;
    font-weight: bold;
    margin-top: 5px;
    color: var(--secondary-color);
  }
  
  .calendar-time {
    font-size: 2rem;
    font-weight: 600;
  }
  
  @media screen and (max-width: 576px) {

    .reservation-price{
      font-size: 1.5rem;
    }
    .reservation-price .amount{
      font-size: 2rem;
    }
    .reservation-info-course{
      font-size: 1.5rem;
    }
    .calendar-time {
      font-size: 1.5rem;
    }
  }
.message-item{
    max-width: calc(50% - 1rem);
    padding: 2rem;
}

@media screen and (max-width:767px) {
    .message-item{
        max-width: 100%;
        padding: 2rem;
    }
}
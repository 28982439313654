
  .scrollable-list {
    max-height: 125px;
    overflow-y: auto;
    padding: 5px;
    
    /* Hide scrollbar for Webkit browsers (Chrome, Safari) */
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
}

.scrollable-list::-webkit-scrollbar {
    display: none; /* Chrome, Safari */
}

  
  .list-item {
    border-bottom: 1px solid gray;
    padding: 5px;
    cursor: pointer;
  }
  
  .list-item:hover {
    background-color: #f0f0f0;
  }
  
  .selected-courses {
    margin-top: 10px;
  }
  
  .selected-item {
    display: inline-block;
    margin-right: 10px;
    padding: 5px;
    background: #59b2a5;
    color: white;
    border-radius: 5px;
  }
  
  .selected-item span {
    cursor: pointer;
    margin-left: 5px;
  }
  

  .list-item.selected {
    background-color: var(--primary-color);
    border-radius: 5px;
    color: white;
    font-weight: bold;
  }



/* Sroll X container */

.scroll-position{
  position: relative;
  z-index: 0;

}

.scroll-container2 {
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: none; /* Hides scrollbar in Firefox */
  scrollbar-color: #ccc transparent; /* Firefox */

}

.scroll-container2::-webkit-scrollbar {
  display: none; /* Hides scrollbar in Chrome, Safari, Opera */
}


.flex-inline {
  display: inline-flex;
}


.scroll-container {
  max-height: calc(3 * 7rem + 2rem); /* Adjust height to fit 2 items */
  overflow-y: scroll;
  position: relative; /* For proper layering */
  -webkit-mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 85%, rgba(0, 0, 0, 0) 100%); /* Start fading just before the end */
  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 85%, rgba(0, 0, 0, 0) 100%); /* Start fading just before the end */
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
}


@media screen and (max-width:576px) {
  .scroll-container {
    max-height: 100vh; /* Adjust height to fit 2 items */
    padding: 1rem 0;
  }
}
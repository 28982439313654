h1 {
  font-size: 3.5rem;
  max-width: 85vw;
  overflow: hidden; /* Ensure content beyond width is hidden */
  white-space: nowrap; /* Prevent wrapping */
  text-overflow: ellipsis; /* Add ellipsis for overflowed text */
  display: inline-block; /* Ensure ellipsis works */
}

h2 {
  font-size: 1.75rem;
}
.title{ 
  margin: 5rem 0 2rem 0;
}
.title h1 {
  font-size: 3rem;
}

.subtitle h2 {
  display: flex;
  font-size: 2.5rem;
}

.subheading h3 {
  font-size: 1.5rem;
}

.subtitle-dark {
  color: var(--primary-color);
}

a {
  color: white;
}

.primary-color {
  color: var(--primary-color);
}

.secondairy-color {
  color: var(--secondary-color);
}


ul {
  list-style-type: none;
}

p {
  font-weight: 200;
}

.text-overflow{
  max-width: 70vw;
  overflow: hidden; 
  white-space: nowrap; 
  text-overflow: ellipsis; 
  display: inline-block; 
}

.text-overflow-200{
  max-width: 200px;
  overflow: hidden; /* Ensure content beyond width is hidden */
  white-space: nowrap; /* Prevent wrapping */
  text-overflow: ellipsis; /* Add ellipsis for overflowed text */
  display: inline-block; /* Ensure ellipsis works */
}

.bold {
  font-weight: bold;
}

.medium {
  font-weight: 500;
}

.paragraph-width{
  max-width: 600px;
}

@media screen and (max-width: 992px) {
  .subtitle h2 {
    font-size: 2rem;
  }
}
@media screen and (max-width: 576px) {
  h1 {
    font-size: 2rem;
  }
  .title h1 {
    font-size: 2rem;
  }
  .subtitle h2 {
    font-size: 1.75rem;
  }
}

.sticky-component {
    display: flex;
    flex: 1;
    top: 1rem;
    gap: 20px;
    height: 100%;

  }
  
  .left-side {
    flex: 2;
    min-height: 100vh; /* Just to create enough space to scroll */
  }

  .sticky-side-reservation-detail {
    flex: 1;
    top: 1rem;
    max-width: 400px;
    height: 100%;
    max-height: 550px;
  }
  
  .sticky-side-reservation {
    flex: 1;
    top: 1rem;
    max-width: 400px;
    height: 100%;
    max-height: 550px;
  }

  .sticky-side-user {
    flex: 1;
    top: 1rem;
    max-width: 400px;
    height: 100%;
    max-height: 550px;
  }
  
  .sticky {
    position: sticky;
  }
  

  @media screen and (max-width:1024px) {
    .sticky-side-user {
        position: fixed;
        bottom: 15rem;
        right: .25rem;
        top: unset;
        flex: unset;
        max-width: unset;
        height: unset;
        max-height: unset;
        padding: unset;
        z-index: 1000;
      }
      .sticky-side-reservation {
        position: fixed;
        bottom: 9.5rem;
        right: .25rem;
        top: unset;
        flex: unset;
        max-width: unset;
        height: unset;
        max-height: unset;
        padding: unset;
        z-index: 1000;
      }

      .sticky-side-reservation-detail {
        position: fixed;
        bottom: 9.5rem;
        right: .25rem;
        top: unset;
        flex: unset;
        max-width: unset;
        height: unset;
        max-height: unset;
        padding: unset;
        z-index: 1000;
      }
  }


  /* Sticky filter  */

  .parent-sticky {
    overflow: visible;
  }

  .filter-container {
    position: sticky;
    top: 0;
    padding: 10px;
  background-color: var(--secondary-color);
    z-index: 100; /* Keeps it above other elements */
  }
  
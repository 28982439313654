.clickable-item {
    cursor: pointer; /* Makes it clear it's clickable */
    padding: 10px;
    margin: 5px 0;
    background-color: var(--primary-color);
    border-radius: 5px;
    transition: opacity 0.2s ease-in-out;
  }
  
  .clickable-item:hover {
    opacity: .9;
  }
  
  .clickable-item:active {
    opacity: .9;
  }
  
  .clickable-item:focus {
    outline: 2px solid #4d90fe; /* Focus state for accessibility */
  }
  
  /* Additional styling for empty lists */
  ul.student-list,
  ul.teacher-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  ul.student-list li,
  ul.teacher-list li {
    list-style: none;
  }
  
  ul.student-list {
    max-height: 200px;
    overflow-y: auto; /* Enable scroll */
  }
  
  ul.teacher-list {
    max-height: 200px;
    overflow-y: auto; /* Enable scroll */
  }
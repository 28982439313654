
.stats-amount{
    font-size: 1.5rem;
    font-weight: 600;
}

@media screen and (max-width: 992px) {
    .stats{
        padding: 1rem 0;
    }
    .stats-item{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .stats-amount{
        font-size: 2rem;
        font-weight: 600;
    }
}
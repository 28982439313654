.Footer{
    overflow: hidden;
    padding: 5rem 0 1rem 0;
}

.socials{
    padding: 0 0 2rem 0;
}

.p-footer{
    padding:5rem 0;
}

.footer-links li a{
    font-weight: 800;
    text-decoration: none;
}

@media screen and (max-width: 576px) {
    .Footer{
        padding: 5rem 0 5rem 0;
    }
}
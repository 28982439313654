.input {
  display: flex;
  flex-direction: column;
  /* width: 100%; */
  /* max-width: 250px; */
  padding: 0.5rem 0;
}
.input button img {
  width: 1rem;
}

.input input,
textarea,
select {
  font-family: "Fredoka", sans-serif;
  width: 100%;
  font-size: 1rem;
  padding: 1rem 1rem;
  border: none;
  background-color: white;
  border-radius: 7.5px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}



.input textarea {
  height: 10rem;
  max-width: 100%;
  max-height: 15rem;
}

.input input:disabled,
.input textarea:disabled,
.input select:disabled {
  background-color: rgb(240, 240, 240); /* Lighter gray background */
  color: gray; /* Gray text */
  cursor: not-allowed; /* Show a 'not allowed' cursor */
  border: 1px solid #d3d3d3; /* Light gray border */
}

.disabled-range {
  opacity: 0.5;
  cursor: not-allowed;
  background: #ddd;
}

.disabled-range::-webkit-slider-thumb {
  background: #bbb;
}

.disabled-range::-moz-range-thumb {
  background: #bbb;
}
